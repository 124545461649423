import { cloneDeep, get, isArray, isBoolean, isFunction, isEqual, isEmpty, isObject, isString, filter, some, throttle, pick } from 'lodash';
import 'frontend-video-player/webcomponent';
import { addEventListener } from '../../../utils/dom';
import { getLabels } from './i18n';
import { Configurator as SideBarConfigurator } from './side-bar/Configurator';
import { questionLinks } from '../../../filters';
import { checkIfImageLoads, getEventIconUrl, getStaticAsset } from '../../../utils/assets';

const COUNTDOWN_STATES = {
    STREAM_IS_LIVE: 'streamIsLive',
    COUNTDOWN_ONGOING: 'countdownOngoing',
    COUNTDOWN_ENDED: 'countdownEnded',
    STREAM_ENDED: 'streamEnded'
};

const ANALYTICS_TRACK_JITTER = 10 * 1000;
const COUNTDOWN_ANALYTICS_INTERVAL = 30 * 1000;

const MAX_WINDOW_WIDTH_FOR_MOBILE_EXPERIENCE = 991; // px

const getInterprefyUrl = (interprefyProjectId = null) => `https://interpret.world/loginlink?token=${interprefyProjectId}`;

export const VideoPlayerComponent = {
    template: require('./video-player.jade'),
    bindings: {
        config: '<',
        sourceUrl: '<',
        isIframeContent: '=',
        broadcastStarted: '=',
        media: '<',
        autoplay: '<',
        useMetadata: '=',
    },
    controller: class VideoPlayerComponent {
        /* @ngInject */ constructor(
            $element,
            $eventBus,
            $i18n,
            $filter,
            $scope,
            $interval,
            ACTIVATED_PERSON,
            EID,
            EVENT,
            SETTINGS,
            THEME,
            triggerService,
            reactionsService,
            liveStreamService,
            metricsService,
            navService,
            qnaService,
            pollService,
            signalService,
            targetingService,
            videoPlayerService
        ) {
            this.$element = $element;
            this.$eventBus = $eventBus;
            this.$scope = $scope;
            this.$filter = $filter;
            this.$i18n = $i18n;
            this.$interval = $interval;
            this.theme = THEME;
            this.settings = SETTINGS;
            this.triggerService = triggerService;

            this.setCaptionTextPosition(null);
            this.ACTIVATED_PERSON = ACTIVATED_PERSON;
            this.EID = EID;
            this.EVENT = EVENT;

            this.isMaximised = false;
            this.reactionsService = reactionsService;
            this.liveStreamService = liveStreamService;
            this.signalService = signalService;
            this.qnaService = qnaService;
            this.pollService = pollService;
            this.navService = navService;
            this.targetingService = targetingService;
            this.videoPlayerService = videoPlayerService;
            this.videoInPipMode = false;

            this.listeners = [];
            this.countdownState = '';

            this.metricsService = metricsService;
            this.analyticsJitter = Math.floor(Math.random() * ANALYTICS_TRACK_JITTER);
            this.countdownInterval = undefined;
            this.sendAnalyticsTimeout = undefined;
            this.getNewCookiesOnPlay = false;
            this.videoSessionId = null;

            this.configIsReady = false;

            this.url = '';
            this.dummyVideoIsPlaying = false;
            this.isPlayerFullscreen = false;

            this.shouldShowCrashMessage = false;
            this.hadNetworkError = false;

            this.addResolutionControl = false;

            try {
                this.isLocalDevNode = "aws-dev" === 'dev';
            } catch (e) {
                console.log(e);
            }

            this.cmcdSessionId = ACTIVATED_PERSON._id + '@' + Date.now();
            this.playerLabels = getLabels(this.$i18n, ACTIVATED_PERSON);
        }

        $onInit() {
            this.isInPerson = this.liveStreamService.isInPerson(this.config);
            this.isToggleStreamTabEnabled = this.liveStreamService.isToggleStreamTabEnabled(this.config);
            this.isThirdParty = this.config.isThirdParty;
        }

        async $postLink() {
            console.log('[VideoPlayer] initializing video player');

            if (this.$element.closest('.block').length) {
                // This is a special case for the video in feeds
                const width = this.$element.width();
                this.$element.height(width * (9 / 16));
            }

            const eventIcon = getEventIconUrl(this.EVENT);
            this.eventIcon = await checkIfImageLoads(eventIcon) ? eventIcon : null;

            const showTranslationsTab = () => {
                return !!get(this.config, 'multicast_session_id', '');
            };

            this.sideBarConfigurator = new SideBarConfigurator(
                {
                    navService: this.navService,
                    signalService: this.signalService,
                    pollService: this.pollService,
                    qnaService: this.qnaService,
                    targetingService: this.targetingService,
                    liveStreamService: this.liveStreamService,
                    EVENT: this.EVENT,
                    THEME: this.theme,
                    $i18n: this.$i18n,
                    $filter: this.$filter,
                    settings: this.settings,
                    eventBus: this.$eventBus
                },
                {
                    livestream: this.config,
                    labels: this.playerLabels,
                    eventIcon: this.eventIcon
                },
                {
                    useMetadata: () => this.useMetadata,
                    playerApi: () => this.playerApi,
                    isIframeContent: () => this.isIframeContent,
                    isSmallScreen: () => this.isSmallScreen,
                    isStreamless: () => this.isStreamless,
                    isInPerson: () => this.isInPerson,
                    isVod: () => this.isVod,
                    streamEnded: () => this.streamEnded,
                    showViewers: () => this.showViewers,
                    showTranslationsTab: () => showTranslationsTab(),
                    showStreamTab: () => this.showStreamTab,
                    isPrivateQna: () => this.isPrivateQna
                },
                () => this.updatePlayerConfig()
            );

            let skipNextFullscreenEvent = false;
            const onNativeFullscreenChange = async () => {
                if (skipNextFullscreenEvent) {
                    skipNextFullscreenEvent = false;
                    return;
                }

                if (document.fullscreenElement) {
                    skipNextFullscreenEvent = true;
                    await document.exitFullscreen();
                }
                this.toggleClientFullscreen();
            };

            this.setupWindowWidthListener();

            this.listeners.push(
                // When an iframe url changes, the reference to the trustAsResourceUrl changes but not its value (?!),
                // hence we need to set the third argument of the watcher (objectEquality) to false
                // For a video url, the reference to the string changes and also its value, false is ok too
                this.$scope.$watch('$ctrl.sourceUrl', () => this.setUrl(), false),
                this.$scope.$watch('$ctrl.config', () => this.onNewConfig(), true),
                this.$scope.$watch('$ctrl.isIframeContent', () => this.newIframeContent(), true),
                this.$scope.$watch('$ctrl.useCredentials', () => this.setUrl(), true),
                this.$scope.$watch('$ctrl.playerHidden', () => this.setUrl(), true),
                this.$scope.$watch('$ctrl.broadcastStarted', () => {
                    this.sideBarConfigurator.resolveTabs();
                    this.updatePlayerConfig();
                }, true),
                this.$scope.$on('video-pip:float', () => {
                    this.videoInPipMode = true;
                    this.updatePlayerConfig();

                    this.showNotifications();
                }),
                this.$scope.$on('video-pip:embed', () => {
                    this.videoInPipMode = false;
                    this.updatePlayerConfig();

                    this.hideNotifications();
                }),
                this.$scope.$on('video-player:premature-stop', () => this.onPrematureStop()),
                addEventListener(this.player, 'fullscreenchange', onNativeFullscreenChange),
                addEventListener(this.player, 'mozfullscreenchange', onNativeFullscreenChange),
                addEventListener(this.player, 'webkitfullscreenchange', onNativeFullscreenChange),
                addEventListener(this.player, 'msfullscreenchange', onNativeFullscreenChange),
                addEventListener(window, 'beforeunload', () => this.sendUpdatedAnalytics()),
                this.$eventBus.on('video-player:force-pause', ({ emitter }) => {
                    if (emitter === this) {
                        return;
                    }
                    console.log('[VideoPlayer] pausing because another player started');

                    this.playerApi.pause();
                    this.getNewCookiesOnPlay = true;
                }),
                this.triggerService.addTrigger('reset', () => {
                    this.playerApi.clientAction(true, 'resetController');
                }),
                this.triggerService.addTrigger('resolution', () => {
                    this.addResolutionControl = true;
                    this.updatePlayerConfig();
                })
            );

            this.hideNotifications();

            this.onNewConfig(true);
            await this.setUrl();

            this.setupPlayer();
        }

        $onDestroy() {
            this.removeAnalyticsInterval();
            clearTimeout(this.sendAnalyticsTimeout);
            this.sendUpdatedAnalytics();

            this.playerApi.dispose();

            for (const unsub of this.listeners) {
                if (isFunction(unsub)) {
                    unsub();
                }
            }

            if (this.sideBarConfigurator) {
                this.sideBarConfigurator.destroy();
            }

            this.showNotifications();
        }

        showNotifications() {
            this.$eventBus.emit('notifications:show', `polls-${this.liveStreamId}`);
        }

        hideNotifications() {
            this.$eventBus.emit('notifications:hide', `polls-${this.liveStreamId}`);
        }

        async newIframeContent() {
            this.updatePlayerConfig();
        }

        onNewConfig(initialUpdate = false) {
            this.useCredentials = this.liveStreamId && (this.isVod || !!get(this, 'config.cookies') || this.useCredentials) && !this.isLocalDevNode
                || this.config && this.config.fp_type === 'video';
            this.sideBarConfigurator.onLivestreamUpdate(this.config, initialUpdate);
            this.updatePlayerConfig();
        }

        async setUrl() {
            this.dummyVideoIsPlaying = false;
            if (!this.liveStreamId) {
                if (this.sourceUrl) {
                    this.url = this.sourceUrl;
                } else if (isString(this.media)) {
                    this.url = this.media;
                } else {
                    this.url = get(this.media, 'sources[0].source', null);
                }
            } else if (this.isThirdParty || this.isIframeContent) {
                this.url = this.playerHidden ? '' : this.sourceUrl;
            } else if (this.useCredentials || (this.isLocalDevNode && this.sourceUrl)) {
                // for Studio or RTMPS livestreams, cookies are required but not always set, setting url without cookies -> harmless CORS error in console
                this.url = this.sourceUrl;
            } else if (!this.isFinished) {
                // always play some video in the player, even if the livestream is not yet available, this way Chrome won't
                // throttle the tab or prevent audio autoplay on source change, see EP-12924
                this.url = getStaticAsset('media/video.mp4');
                this.dummyVideoIsPlaying = true;
            } else {
                this.url = '';
            }
            // if the url needs cookies from our backend, or to be signed, we need to consume the provided url and use the returned one
            this.url = await this.videoPlayerService.getCookies(this.url);
            console.log('[VideoPlayer] set url', { url: this.url, loop: this.dummyVideoIsPlaying });
        }

        setupPlayer() {
            const player = this.player;

            if (!player) return;

            player.config = this.playerConfig;

            if (this.liveStreamId) {
                this.setupClaps();
                this.setupQna();
                if (!this.isVod) {
                    this.setupCountdown();
                }
                this.sideBarConfigurator.init(this.config);

                this.getInitialState();
            }

            if (!some(this.playerConfig.components, this.countdownWidget)) {
                this.configIsReady = true;
            }
            this.$scope.$applyAsync();
        }

        setupWindowWidthListener() {
            this.isSmallScreen = false;

            const windowResizeListener = throttle(() => {
                const isSmallScreen = window.innerWidth <= MAX_WINDOW_WIDTH_FOR_MOBILE_EXPERIENCE;
                if (this.isSmallScreen !== isSmallScreen) {
                    this.isSmallScreen = isSmallScreen;
                    this.$scope.$emit('isSmallScreen', this.isSmallScreen);
                    this.updatePlayerConfig();
                }
            }, 500);
            this.listeners.push(
                addEventListener(window, 'resize', windowResizeListener)
            );
            windowResizeListener();
        }

        async getInitialState() {
            if (!this.liveStreamId) {
                return;
            }

            if (!this.useMetadata) {
                const qnaState = await this.qnaService.getQuestions(this.liveStreamId);
                this.onQnaSignal(qnaState.qna, qnaState.qnaSortedByUpdates);
            }
        }

        //
        //      Error handling
        //

        onPlayerError(event) {
            const payload = get(event, 'originalEvent.detail.0');
            const context = get(event, 'originalEvent.detail.1');

            if (context !== 'hls') return;

            const { fatal, type, details, response } = pick(
                get(payload, 'data', {}),
                [ 'fatal', 'type', 'details', 'response' ]
            );
            const responseCode = get(response, 'code', 0);

            console.debug('[VideoPlayer] onPlayerError', { fatal, type, details, response });

            const stats = isFunction(this.getUpdatedStats) ? this.getUpdatedStats() : undefined;
            const isTimeout = (details && details.toLowerCase().includes('timeout'))
                || get(payload, 'data.error.message', '').includes('timeout')
                || (type === 'networkError' && !response);
            const requestedResource = (type !== 'networkError' || !details) ? undefined
                : details.startsWith('frag') ? 'segment' : 'playlist';

            this.videoPlayerService.trackError({
                liveStreamId: this.liveStreamId,
                parentDocId: get(this, 'config.analytic_params.document_id'),
                videoElapsedSecs: get(this.playerApi.getState(), 'videoElapsedSecs'),
                url: get(payload, 'data.url'),
                errorType: type,
                errorDetails: details,
                responseCode,
                fatal,
                videoSessionId: this.videoSessionId,
                isTimeout,
                requestedResource,
                resolution: get(stats, 'playlist_resolution'),
                org: this.EVENT.orgName
            });

            // CORS errors defaults to a 0 error code in hls.js XMLHttpRequest, but ends up as a fatal smvpError when all the buffer was played
            const isAuthError = type === 'networkError' && (responseCode === 401 || responseCode === 403);
            const probablyCORSerror = this.hadNetworkError && fatal && details === 'smvpError';
            if (isAuthError || probablyCORSerror) {
                console.error('[VideoPlayer] detected some potential auth/CORS issue, stopping playback and flag to reload on play', { fatal, type, details, response });
                this.playerApi.pause();
                this.getNewCookiesOnPlay = true;
                this.hadNetworkError = false;
                return;
            } else if (fatal && details === 'smvpError') {
                this.hadNetworkError = false;
                return this.handleFatalHlsError();
            }
            this.hadNetworkError = this.hadNetworkError || type === 'networkError';
        }

        onPrematureStop() {
            this.videoPlayerService.trackError({
                liveStreamId: this.liveStreamId,
                parentDocId: get(this, 'config.analytic_params.document_id'),
                videoElapsedSecs: get(this.playerApi.getState(), 'videoElapsedSecs'),
                url: get(this, 'config.url'),
                errorType: 'prematureStop',
                errorDetails: 'premature stop, id3 contained is_live: false before we got a live stream document with is_finished: true',
                videoSessionId: this.videoSessionId
            });
        }

        showCrashMessage() {
            this.shouldShowCrashMessage = true;
            this.updatePlayerConfig();
        }

        removeCrashMessage() {
            this.shouldShowCrashMessage = false;
            this.updatePlayerConfig();
        }

        /**
         * Only user action can recover from fatal errors
         */
        handleFatalHlsError() {
            this.showCrashMessage();
        }

        // - /ERROR HANDLING

        onCustomAction(event) {
            const payload = get(event, 'originalEvent.detail.0');
            const context = get(event, 'originalEvent.detail.1');

            switch (context) {
                case 'clientFullscreen':
                    return this.toggleClientFullscreen();
                case 'fullscreen':
                    return this.toggleFullscreen(payload);
                case 'claps':
                    return this.handleClaps(payload);
                case 'qna':
                    this.sideBarConfigurator.onEvent(context, payload);
                    return this.onQnaMetadata(payload);
                case 'addQuestionUpvote':
                    return this.addQuestionUpvote(payload);
                case 'removeQuestionUpvote':
                    return this.removeQuestionUpvote(payload);
                case 'polls':
                    return this.sideBarConfigurator.onEvent(context, payload);
                case 'countdown':
                    return this.onCountdownEvent(payload);
                case 'sideBarViewers':
                    return this.sideBarConfigurator.onSideBarEvent(context, payload);
                case 'sideBarQna':
                    return this.sideBarConfigurator.onSideBarEvent(context, payload);
                case 'sideBarQnaSortBy':
                    return this.updateSideBarQnaSortBy(payload);
                case 'sideBarPolls':
                    return this.sideBarConfigurator.onSideBarEvent(context, payload);
                case 'sideBarNotification':
                    return this.handleSidebarNotification(payload);
                case 'hls':
                    return this.onHlsAction(payload);
                case 'sideBarStream':
                    return this.sideBarConfigurator.onSideBarEvent(context, payload);
            }
        }


        addQuestionUpvote(question) {
            const qnaTab = this.sideBarConfigurator.getTabInstance('qna');
            qnaTab.addUpvote(question.id);
            this.updateHighlightedQuestionUpvotes(question.id);
        }

        removeQuestionUpvote(question) {
            const qnaTab = this.sideBarConfigurator.getTabInstance('qna');
            qnaTab.removeUpvote(question.id);
            this.updateHighlightedQuestionUpvotes(question.id);
        }

        updateSideBarQnaSortBy(sortOption) {
            const qnaTab = this.sideBarConfigurator.getTabInstance('qna');
            qnaTab.updateSortBy(sortOption);
        }

        updateHighlightedQuestionUpvotes(questionId, newQuestion = null) {
            const qnaTab = this.sideBarConfigurator.getTabInstance('qna');
            if (this.highlightedQuestion && this.highlightedQuestion.id === questionId) {
                const q = newQuestion || qnaTab.getQuestion(questionId);
                const newHighlightedQuestion = cloneDeep(this.highlightedQuestion);
                newHighlightedQuestion.upvotes = q.upvotes;
                newHighlightedQuestion.upvoted = q.upvoted;
                this.processHighlightedQuestion(newHighlightedQuestion);
            }
        }

        onHlsAction(payload) {
            const { event } = (payload || {});
            switch (event) {
                case 'bufferingEnded':
                case 'smvpErrorRecover':
                    return this.onPlaybackResumedHandler();
                case 'hlsLevelUpdated':
                    return this.hlsLevelUpdated(payload);
            }
        }

        onPlaybackResumedHandler() {
            this.removeCrashMessage();
        }

        hlsLevelUpdated(payload) {
            const { isLive } = payload;
            console.log('[VideoPlayer] hlsLevelUpdated', { isLive });
        }

        onPlayerAnalytics(event) {
            const payload = get(event, 'originalEvent.detail.0', {});
            const context = get(event, 'originalEvent.detail.1');

            const stats = payload.stats || {};

            const dummyUrl = getStaticAsset('media/video.mp4');
            if (get(stats.player, 'source.src') === dummyUrl) {
                return;
            }

            this.getUpdatedStats = payload.getUpdatedStats;

            switch (context) {
                case 'hls':
                    return this.sendAnalytics({ stats });
            }
        }

        onVideoPlayerStateChanged(event) {
            const payload = get(event, 'detail.0', {});
            const isPlaying = payload.isPlaying;

            if (!isBoolean(isPlaying)) {
                return;
            }

            if (isPlaying) {
                console.log('[VideoPlayer] playback started');

                if (this.getNewCookiesOnPlay) {
                    this.getNewCookiesOnPlay = false;
                    this.videoPlayerService.getCookies(this.sourceUrl);
                }
                this.$eventBus.emit('video-player:force-pause', { emitter: this });
            } else {
                console.log('[VideoPlayer] playback stopped', payload);
            }
        }

        sendUpdatedAnalytics() {
            if (isFunction(this.getUpdatedStats)) {
                const updatedStats = this.getUpdatedStats();
                this.sendAnalytics({ stats: updatedStats, immediate: true });
            }
        }

        sendAnalytics({ stats, immediate = false } = {}) {
            if (isEmpty(stats)) {
                return;
            }

            this.videoSessionId = stats.video_session_id;
            if (this.liveStreamId) {
                stats.fp_type = 'live_stream';
                stats.document_id = this.liveStreamId;
            } else {
                stats.fp_type = this.config.fp_type;
                stats.document_id = this.config.document_id;
            }

            if (!stats.document_id || !stats.fp_type) {
                return;
            }

            if (immediate) {
                return this.metricsService.trackAnalytics(stats);
            }

            this.sendAnalyticsTimeout = setTimeout(() => {
                stats.jitter = this.analyticsJitter;
                this.metricsService.trackAnalytics(stats);
            }, this.analyticsJitter);
        }

        toggleClientFullscreen() {
            this.isMaximised = !this.isMaximised;

            if (this.isMaximised) {
                this.$scope.$emit('theatreMode:on');
            } else {
                this.$scope.$emit('theatreMode:off');
            }

            this.playerApi.clientAction(this.isMaximised, 'clientFullscreen');
        }

        toggleFullscreen({ isFullScreen } = {}) {
            // here going fullscreen is handled by the player, below we emit the fullscreen state to block scrolling at the body level
            this.isPlayerFullscreen = isFullScreen;
            if (isFullScreen) {
                this.$eventBus.emit('theatreMode:enabled');
            } else {
                this.$eventBus.emit('theatreMode:disabled');
            }
            this.updatePlayerConfig();
        }

        updatePlayerConfig() {
            const player = this.player;
            if (!this.configIsReady) {
                return;
            }
            player.config = this.playerConfig;
        }

        setCaptionTextPosition() {
            const isSomethingAlreadyOnScreen = this.hasSideBarNotificationOnScreen || !!this.highlightedQuestion;
            const currentTextPosition = this.captionTextPosition;
            this.captionTextPosition = isSomethingAlreadyOnScreen ? 'top' : 'bottom';
            if (currentTextPosition !== this.captionTextPosition) {
                this.updatePlayerConfig();
            }
        }

        handleSidebarNotification(payload) {
            this.hasSideBarNotificationOnScreen = !isEmpty(payload);
            this.setCaptionTextPosition();
        }

        /* --------------
         * Countdown widget
         * -------------- */

        setupCountdown() {
            this.countdownInterval = this.$interval(
                () => this.updateCountdownAnalytics(),
                COUNTDOWN_ANALYTICS_INTERVAL
            );
            this.updateCountdownAnalytics(true);
        }

        updateCountdownAnalytics(skipPlayerCheck) {
            if (!this.playerHidden && !skipPlayerCheck) {
                return this.removeAnalyticsInterval();
            }

            this.metricsService.trackAnalytics({
                name: 'waiting',
                document_id: this.liveStreamId,
                document_type: 'live_stream'
            });
        }

        removeAnalyticsInterval() {
            if (this.countdownInterval) {
                this.$interval.cancel(this.countdownInterval);
            }
        }

        get playerHidden() {
            return this.countdownState !== COUNTDOWN_STATES.STREAM_IS_LIVE;
        }

        async reactToCountdownStateChange(newState) {
            this.countdownState = newState;
            if (this.isThirdParty) {
                await this.setUrl();
            }
            this.updatePlayerConfig();
        }

        async onCountdownEvent(payload) {
            if (payload.streamIsLive) {
                this.$scope.$emit('live-stream:start-broadcast-from-id3');
            }
            if (payload.streamEnded) {
                this.$scope.$emit('live-stream:stop-broadcast-from-id3');
            }
            if (payload.newState) {
                await this.reactToCountdownStateChange(payload.newState);
            }
            if (!this.configIsReady) {
                this.configIsReady = true;
                this.updatePlayerConfig();
                this.$scope.$applyAsync();
            }
        }

        /* --------------
         * Claps widget
         * -------------- */

        setupClaps() {
            this.reactionsService
                .onClapsSignal(this.liveStreamId, ({ reactions }) =>
                    this.onClapsSignal(reactions))
                .then(remover => this.listeners.push(remover));
        }

        onClapsSignal(reactions) {
            const claps = get(reactions, 'claps', 0);

            this.playerApi.clientAction(claps, 'claps');
        }

        handleClaps(payload) {
            if (payload <= 0) return;

            this.reactionsService.sendClaps(
                this.liveStreamId,
                payload
            );
        }

        /* --------------
         * Qna widget
         * -------------- */

        async setupQna() {
            this.listeners.push(
                await this.signalService.addSignalListener(
                    this.qnaService.updateSignal(this.liveStreamId),
                    (signal) => { this.onQnaSignal(signal.questions, signal.questionsSortedByUpvotes); }
                )
            );
        }

        onQnaSignal(questions, questionsSortedByUpvotes) {
            if (!get(questions, 'length')) return;

            const allQuestions = (questionsSortedByUpvotes && questionsSortedByUpvotes.length > 0) ?
                questions.concat(questionsSortedByUpvotes) :
                questions;

            if (this.isThirdParty) {
                const highlight = allQuestions.find(q => q.status === 'highlighted');

                this.processHighlightedQuestion(highlight);
            } else if (this.config.qna_upvoting_enabled && this.highlightedQuestion) {
                const q = allQuestions.find(i => i.id === this.highlightedQuestion.id);
                if (q) {
                    this.updateHighlightedQuestionUpvotes(q.id, q);
                }
            }
        }

        onQnaMetadata(payload) {
            this.processHighlightedQuestion(payload);
        }

        processHighlightedQuestion(question) {
            if (!question || !question.question) {
                this.playerApi.clientAction(null, 'qna');
                this.highlightedQuestion = null;
                this.setCaptionTextPosition();
                return;
            }

            question.question = questionLinks(this.$filter, this.$i18n, question.question);
            question = this.qnaService.resolveProfilePicture(question, this.eventIcon);
            question.showUserInitials = get(this.settings, 'person.show_initials', true);

            const sideBarQuestion = this.findQuestionInSideBar(question.id);
            if (sideBarQuestion) {
                question.upvotes = sideBarQuestion.upvotes;
                question.upvoted = sideBarQuestion.upvoted;
            }

            this.qnaService.onQnaHighlight(question.id);
            this.playerApi.clientAction(question, 'qna');
            this.highlightedQuestion = question;
            this.setCaptionTextPosition();
        }

        findQuestionInSideBar(questionId) {
            const qnaTab = this.sideBarConfigurator ? this.sideBarConfigurator.getTabInstance('qna') : null;
            if (!qnaTab) {
                return null;
            }
            return qnaTab.getQuestion(questionId);
        }

        // getters
        get liveStreamId() {
            return get(this.config, 'liveStreamId');
        }

        /**
         * example of returned value:
         * {
         *     "player": {
         *         "filter": [
         *             "fullscreen",
         *             "client-fullscreen"
         *         ],
         *         "components": [
         *             "resolution"
         *         ]
         *     },
         *     "hls": {
         *         "capLevelToPlayerSize": false,
         *         "autoLevelCapping": 0
         *     }
         * }
         */
        get configOverrides() {
            return get(this.config, 'configOverwrites') || get(this.config, 'configOverrides');
        }

        get hlsConfigOverrides() {
            return get(this.configOverrides, 'hls');
        }

        get playerConfigOverrides() {
            return get(this.configOverrides, 'player');
        }

        get documentId() {
            return this.liveStreamId || get(this.config, 'document_id') || get(this.config, 'analytic_params.document_id');
        }

        // return the state of the live stream document, is_finished is true ± 30s before broadcastStarted is set to false
        get isFinished() {
            return get(this, 'config.is_finished', false);
        }

        get isVod() {
            return this.liveStreamService.isVod(this.config);
        }

        get streamEnded() {
            // stream only ends in the webapp when end-broadcast id3 is received (account for the delay)
            return this.isFinished && !this.broadcastStarted;
        }

        get isRtmps() {
            return this.liveStreamService.isRtmps(this.config);
        }

        get isStreamless() {
            return this.isInPerson && !this.isVod;
        }

        get player() {
            return get(this.$element.find('spotme-video-player'), '0');
        }

        get playerApi() {
            const player = this.player;
            return get(player, 'getApi', () => undefined)();
        }

        get showViewers() {
            const viewers_enabled = get(this.config, 'viewers_enabled', false);

            // Viewers are seen only before and during a live stream. As soon as it ends, and for vod, we remove them
            return viewers_enabled && !this.streamEnded;
        }

        get showStreamTab() {
            // tab appears for hybrid sessions with flag set. only when stream is not VOD
            // (we always want to show the VOD, even for in person attendees)
            return this.isToggleStreamTabEnabled && !this.isVod;
        }

        get isPrivateQna() {
            return get(this.config, 'qna_private_enabled', false);
        }

        get hiveConfig() {
            const hiveConfig = get(this.config, 'hiveConfig') || {};
            const jwt = hiveConfig.jwt;
            if (!jwt) {
                return {};
            }
            return hiveConfig;
        }

        get playerConfig() {
            // Third party streams need the sidebar to display the fullscreen button
            const hasSidebar = (this.sideBarConfigurator.hasTabs || this.isThirdParty) && !this.videoInPipMode;
            this.$scope.$emit('hasSideBar', hasSidebar);

            let components = this.playerComponents();

            return {
                components,
                thumbnail: get(this, 'media.thumbnail'),
                isIframeContent: this.isIframeContent,
                hideControlBar: this.videoInPipMode,
                // If set to false, verify that watching time on mobile is properly accounted for while video in pip and browser in background
                disableNativePip: true,
                labels: this.playerLabels,
                sideBar: hasSidebar ? this.sideBarConfigurator.config : undefined,
                noPlayer: this.isStreamless,
                isSideBarBelow: this.isSmallScreen,
                isWebinar: this.EVENT.is_webinar,
                hiveConfig: this.hiveConfig,
                darkMode: {
                    enabled: this.theme.get('appearance') === 'dark',
                    'background-color': this.theme.backgroundColor3,
                    color: this.theme.foregroundColor1,
                    'border-color': this.theme.backgroundColor2
                }
            };
        }

        playerComponents() {
            const fullscreen = this.isSmallScreen ? 'fullscreen' : 'client-fullscreen';

            if (this.isStreamless) {
                return [];
            }
            if (!this.liveStreamId && this.isIframeContent) {
                // e.g. youtube/vimeo webcast video out of livestream context
                return [];
            }

            // First we add all the components we want depending on the 4 big different cases we have
            // static video (this.liveStreamId is falsy)
            // iframe content (this.isIframeContent = true)
            // "normal" livestreams, in VOD
            // "normal" livestreams, waiting to be live, or already finished
            // /!\ widget order defines their order in z axis /!\
            // /!\ controls order defines their order in x axis /!\
            let components;
            if (!this.liveStreamId) {
                // e.g. feed video or webcast
                components = [
                    this.getBigPlayButtonWidget(),
                    'shortcuts',
                    'play-pause-toggle',
                    'volume',
                    'seek-bar',
                    'time',
                    this.subtitlesWidget,
                    this.languageControl,
                    'fullscreen'
                ];
            } else if (this.isIframeContent && this.playerHidden) {
                // e.g. youtube video in livestream context: the countdown widget ordered to hide the stream
                components = [
                    this.blackOverlayWidget, // countdown widget is transparent, the overlay adds a black background to it
                    this.countdownWidget,
                ];
            } else if (this.isIframeContent) {
                // e.g. youtube video in livestream context: livestream is live and playing
                components = [
                    this.clapsWidget,
                    this.qnaWidget,
                    this.pollWidget,
                    this.countdownWidget // in this case the countdown will hide itself, but needs to listen to the livestream state changes
                ];
            } else if (this.isVod) {
                // e.g. VOD of studio/RTMPS livestreams
                components = [
                    this.slidesBackgroundImageWidget,
                    this.getBigPlayButtonWidget(),
                    'big-unmute-button',
                    'shortcuts',
                    'play-pause-toggle',
                    'volume',
                    'seek-bar',
                    'time',
                    this.viewersCount,
                    this.languageControl,
                    this.interprefyControl,
                    this.helpControl,
                    'resolution',
                    fullscreen,
                    this.subtitlesWidget,
                    this.clapsWidget,
                    this.qnaWidget,
                    this.pollWidget,
                    this.slidesWidget
                ];
            } else if (this.playerHidden) {
                // the countdown widget ordered to hide the stream
                components = [
                    this.viewersCount,
                    fullscreen,
                    this.blackOverlayWidget, // countdown widget is transparent, the overlay adds a black background to it
                    this.countdownWidget,
                    this.userInteractorWidget
                ];
            } else {
                // livestream is live and playing
                components = [
                    this.slidesBackgroundImageWidget,
                    this.getBigPlayButtonWidget(true),
                    'big-unmute-button',
                    'shortcuts',
                    this.playStopToggle,
                    'volume',
                    'live-badge',
                    this.viewersCount,
                    this.subtitlesWidget,
                    this.languageControl,
                    this.interprefyControl,
                    this.helpControl,
                    fullscreen,
                    this.clapsWidget,
                    this.pollWidget,
                    this.qnaWidget,
                    this.slidesWidget,
                    this.countdownWidget // in this case the countdown will hide itself, but needs to listen to the livestream state changes
                ];
            }

            if (this.shouldShowCrashMessage) {
                components.push(this.playerCrashWidget);
            }

            if (this.addResolutionControl) {
                components.push('resolution');
            }

            // Now we filter out all that should not have been here because of fine-tuned settings
            components = this.filterOutImproperComponents(components);

            components = this.applyConfigOverrides(components);

            return components;
        }

        filterOutImproperComponents(components) {
            const hasClaps = get(this, 'config.clapping_enabled', false);
            const hasQna = get(this, 'config.qna');
            const hasInterprefy = !!get(this, 'config.interprefy_project_id');

            if (!hasClaps) {
                components = components.filter((comp) => !isEqual(comp, this.clapsWidget));
            }
            if (!hasQna) {
                components = components.filter((comp) => !isEqual(comp, this.qnaWidget));
            }
            if (!this.showViewers) {
                components = components.filter((comp) => !isEqual(comp, this.viewersCount));
            }
            if (!hasInterprefy) {
                components = components.filter((comp) => !isEqual(comp, this.interprefyControl));
            }
            if (!this.isRtmps) {
                components = components.filter((comp) => comp && comp.id !== 'slides' && !isEqual(comp, this.slidesBackgroundImageWidget));
            } else if (!this.backgroundImageUrl) {
                components = components.filter((comp) => !isEqual(comp, this.slidesBackgroundImageWidget));
            }
            if (this.EVENT.is_webinar) {
                components = components.filter((comp) => comp !== 'fullscreen' && comp !== 'client-fullscreen');
            }
            if (this.countdownState === COUNTDOWN_STATES.STREAM_ENDED) {
                // no need for user interaction if livestream is over
                components = components.filter((comp) => !isEqual(comp, this.userInteractorWidget));
            }
            if (this.videoInPipMode) {
                components = components.filter((comp) => comp !== 'big-unmute-button'
                    && !isEqual(comp, this.getBigPlayButtonWidget())
                    && !isEqual(comp, this.getBigPlayButtonWidget(true))
                    && !isEqual(comp, this.userInteractorWidget)
                    && comp !== 'seek-bar'
                );
            }

            if (this.isThirdParty) {
                // seek to live on play is specifically made for a live stream, static .m3u8 will get seeked to the end on play
                components = components.map((comp) => isEqual(comp, this.playStopToggle) ? 'play-pause-toggle' : comp);
                components = components.map((comp) => isEqual(comp, this.getBigPlayButtonWidget(true)) ? this.getBigPlayButtonWidget(false) : comp);
            }

            return components;
        }

        applyConfigOverrides(components) {
            if (isEmpty(this.playerConfigOverrides)) {
                return components;
            }

            if (isArray(this.playerConfigOverrides.filter)) {
                components = components.filter((comp) =>
                    !this.playerConfigOverrides.filter.includes(comp) &&
                    !(isObject(comp) && this.playerConfigOverrides.filter.includes(comp.id))
                );
            }

            if (isArray(this.playerConfigOverrides.components)) {
                components = components.concat(this.playerConfigOverrides.components);
            }

            return components;
        }

        getBigPlayButtonWidget(seekToLiveOnPlay = false) {
            // If the stream is live, we don't want to show the big play button on mobile
            if (seekToLiveOnPlay && this.isSmallScreen) {
                return;
            }

            return {
                id: 'big-play-button',
                config: {
                    seekToLiveOnPlay
                }
            };
        }

        get playStopToggle() {
            return {
                id: 'play-stop-toggle',
                config: {
                    seekToLiveOnPlay: true
                }
            };
        }

        get viewersCount() {
            return {
                id: 'viewers-count',
                config: {
                    count: get(this, 'sideBarConfigurator.viewersCount', 0)
                }
            };
        }

        get interprefyControl() {
            return {
                id: 'interprefy',
                config: {
                    iframeUrl: getInterprefyUrl(get(this, 'config.interprefy_project_id'))
                }
            };
        }

        get helpControl() {
            return {
                id: 'help',
                config: {
                    onClick: () => {
                        const supportUrl = new URL("https://spotme.typeform.com/to/e32pvfxl");
                        supportUrl.searchParams.append('eid', this.EID);
                        supportUrl.searchParams.append('pid', this.ACTIVATED_PERSON.fp_ext_id);
                        supportUrl.searchParams.append('livestream_id', this.liveStreamId);
                        supportUrl.searchParams.append('url', encodeURIComponent(window.location.href));
                        window.open(supportUrl, '_blank');
                    }
                }
            };
        }

        get languageControl() {
            const live_captions_labels = get(this, 'config.live_captions_labels', {});

            return {
                id: 'language',
                config: isEmpty(live_captions_labels) ? {} : {
                    subtitleTrackNames: live_captions_labels,
                    visibleSubtitleTrackLabels: filter(Object.keys(live_captions_labels))
                }
            };
        }

        get subtitlesWidget() {
            return {
                id: 'subtitles',
                config: {
                    position: this.captionTextPosition
                }
            };
        }

        get clapsWidget() {
            return {
                id: 'claps',
                config: {
                    useMetadata: this.isVod
                },
                hidden: this.videoInPipMode
            };
        }

        get qnaWidget() {
            return {
                id: 'qna',
                config: {
                    useMetadata: this.useMetadata,
                    upvotingEnabled: this.config.qna_upvoting_enabled || false,
                    upvotingAllowed: this.liveStreamService.isUpvotingAllowed(this.config)
                },
                hidden: this.videoInPipMode || (this.isSmallScreen && !this.isPlayerFullscreen)
            };
        }

        get userInteractorWidget() {
            return {
                id: 'user-interactor',
                config: {
                    // if player is hidden, the video might play muted behind the scenes, so we still want to force the user to click
                    // so that we can enable sounds later on
                    destroyOnAutoPlay: !this.playerHidden
                }
            };
        }

        get pollWidget() {
            return {
                id: 'polls',
                config: {
                    showResultsOnly: this.isVod
                },
                hidden: true
            };
        }

        get slidesWidget() {
            return {
                id: 'slides',
                config: {
                    getSlideImageUrl: async (slideshowDocId, slideIndex) => await this.liveStreamService.getSlideImageUrl(slideshowDocId, slideIndex),
                    splitPane: true,
                    useMetadata: true
                }
            };
        }

        get countdownWidget() {
            return {
                id: 'countdown',
                config: {
                    startTime: get(this, 'config.start_time'),
                    streamEnded: this.streamEnded,
                    streamIsLive: this.broadcastStarted
                }
            };
        }

        get blackOverlayWidget() {
            return {
                id: 'overlay',
                config: {
                    color: 'black'
                }
            };
        }

        get backgroundImageUrl() {
            return get(this, 'config.backgroundImageUrl');
        }

        get slidesBackgroundImageWidget() {
            return {
                id: 'overlay',
                config: {
                    imageUrl: this.backgroundImageUrl,
                    color: 'black',
                    behindVideoElement: true
                }
            };
        }

        get playerCrashWidget() {
            const { title, message, buttonLabel } = this.playerLabels['crash-overlay'];
            return {
                id: 'message-overlay',
                config: {
                    onTop: true,
                    title,
                    message,
                    link: 'https://support.spotme.com/hc/en-us/articles/360052063893-Troubleshooting-for-app-users#h_01EGATKZRMDBNA4GQ5CV09K18Y',
                    button: {
                        text: buttonLabel,
                        action: () => window.location.reload()
                    }
                }
            };
        }
    }
};
